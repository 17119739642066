export const s3bucketurl:string='https://testbucketmarket.s3.ap-south-1.amazonaws.com/'

export const api_urls: { [key: string]: [string, string, string, string] } = {
    createLayer:['post' , 'locked' , 'https://l1svt98dea.execute-api.ap-south-1.amazonaws.com/default/layeredFileCreation' , ''] ,
    getPastLayer:['get' , 'locked' , 'https://qmvje4ify1.execute-api.ap-south-1.amazonaws.com/default/layeredplatform_get_pastData' ,''] ,
    getCredits:['get' , 'locked' , 'https://zffm39jckf.execute-api.ap-south-1.amazonaws.com/default/layeredPlatformaccessDetais' ,''] ,
    create_getPaymentlink:['post' ,'locked' ,'https://ea8lhd9ina.execute-api.ap-south-1.amazonaws.com/default/layeredFileCreatePaymentLink' ,'?credits'],
    getPendingLayer:['get' , 'locked' , 'https://6rysy2f0qg.execute-api.ap-south-1.amazonaws.com/default/layeredPlatformPendingrequest' , '']
  };



  