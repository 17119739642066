// src/component/pages/auth.tsx

import React from 'react';
import GoogleSignInButton from '../ui/authpage_elements/googlesignButton';
import { Heading, SmallText } from '../ui/helper';
function Auth() {
  return (
    <div style={{ padding: '10px' , minHeight:'80vh' , marginTop:'3rem'}}>
      <div>
        <SmallText text='Effortlessly create PSD layered and PNG files from flat images.'/>
      </div>
      <Heading text='Bringing life to flat images.'/>
      <h3 > 5 Free Layered File on Signup</h3>
    <div style={{display: 'flex', justifyContent: 'center' , marginTop:'4rem'}}>
      <GoogleSignInButton />
    </div>
    <div style={{display:'flex' , alignItems:'center' , justifyContent:'space-around' , gap:'5rem' , flexWrap:'wrap' , marginTop:'2rem'}}>
      <img src='assets/full dog.png'/>
      <img src='assets/Group 66.png'/>
    </div>
    </div>
  );
}

export default Auth;
