import React, { FC, useEffect, useState } from 'react';
import { exampleText, faqText, logoimg, secondrycolor, primarycolor } from '../constants/constants';
import { PrimaryButton, SmallText, UrlText  } from './helper';
import { logout } from '../auth/helper';
import { api_urls } from '../api/list';
import { Locked_getApi_authfill } from '../api/helper';
import './header.css';
import mixpanel from 'mixpanel-browser';
interface NavbarProps {}

const Header: FC<NavbarProps> = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [Credits , setCredits]=useState(0);
    async function access() {
        if (window.location.pathname==='/auth')
        return
        let data2=await Locked_getApi_authfill(api_urls['getCredits'][2],true)
        mixpanel.identify(data2['uid'])
        setCredits(data2['credits'])
        
      }
    useEffect(() => { 
        console.log(window.location.pathname);
        setFadeIn(true);
        access()
    }, []);

    return (
        <div style={{border: '0.34px #EDEDED solid ' , width:'100%'}} >
        <div style={{width:'100%' , display:'flex' , justifyContent:'center'}}>
        <div 
            style={{
                width:'100%',
                display:'flex',
                alignItems:'center',
                background: primarycolor,
                animation: fadeIn ? 'fadeIn 0.2s ease-in-out' : '',
                padding:'10px 0px' ,
                justifyContent:'space-between'
            }}
        >
         
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' , width:'fit-content' }}>
            <a href='/'>
            <img 
                style={{
                    maxWidth: '40vw',
                    maxHeight: '8vh',
                    animation: fadeIn ? 'fadeIn 0.2s ease-in-out' : '' , 
                    
                }} 
                src={logoimg} 
                alt="Logo"
            />
            </a>
            <a href="/" className="hide-on-mobile" style={{ color: secondrycolor, textDecoration: 'none' }} > <UrlText text='Create Layered File'/></a>

                <a href="https://www.imagepsdlayers.com/how-to-use.html" className="hide-on-mobile" style={{ color: secondrycolor, textDecoration: 'none' }} > <UrlText text='How to Use'/></a>
                <a href={window.location.pathname!=='/auth'?"/Pricing":'https://www.imagepsdlayers.com/index.html#pricingDiv'}  className="hide-on-mobile" style={{ color: secondrycolor, textDecoration: 'none' }} > <UrlText text='Pricing'/></a>
                
            </div>

            
            
            {window.location.pathname!=='/auth' &&
            <div style={{display: 'flex', alignItems: 'center', gap: 'calc(min(5vw,2rem))' ,  marginRight:'2rem'}}>
            <div  className="hide-on-mobile" style={{width:'5rem', animation: fadeIn ? 'fadeIn 0.2s ease-in-out' : ''}}>
                <SmallText text={'Credits:'+Credits.toString()}/>
           </div>
               <PrimaryButton text='Logout' onClickfunction={logout}/>
            </div>
            }
        </div>
        
        </div>
        <div className="display-on-mobile"   style={{ display: 'flex', alignItems: 'center' , justifyContent:'center', gap: '1rem' , width:'100%' }}>
            <a href="/" style={{ color: secondrycolor}} > <UrlText text='Create'/></a>

                <a href="https://www.imagepsdlayers.com/how-to-use.html" style={{ color: secondrycolor }} > <UrlText text='How to Use'/></a>
                <a href={window.location.pathname!=='/auth'?"/Pricing":'https://www.imagepsdlayers.com/index.html#pricingDiv'}  style={{ color: secondrycolor }} > <UrlText text='Pricing'/></a>
                
            
        {window.location.pathname!=='/auth' && 
        <div   style={{width:'5rem', animation: fadeIn ? 'fadeIn 0.2s ease-in-out' : ''}}>
            <SmallText text={'Credits:'+Credits.toString()}/>
        
        </div>
}
</div>
</div>
    );
};

export default Header;
