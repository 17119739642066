export function dataURItoBlob(dataURI:any) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

export async function Compress(selectedFile:File) {
    console.log('into to compress');
    var file = selectedFile;
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var img = new Image();
    img.src = URL.createObjectURL(file);
    await new Promise(res => img.onload = res);
    let scale = 1;  // Starting with original size
    let result;

    do {
        if(ctx) {
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            result = canvas.toDataURL("image/png");
            scale -= 0.1;
        }
        console.log(scale , dataURItoBlob(result).size);
    } while (dataURItoBlob(result).size / 1024 > 1024 && scale > 0);
  
    return result;
}
