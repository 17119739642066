 import { getIdToken } from "../auth/helper";

/**
 * Generates a URL with query parameters.
 * @param {string} url - The base URL.
 * @param {Object} params - The query parameters.
 * @return {string} The generated URL.
 */
export function generateUrl(url: string, params: {[key: string]: string}): string {
    const queryString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    return `${url}?${queryString}`;
}

/**
 * Sends a GET request to the specified link.
 * @param {string} link - The link to send the request to.
 * @return {Promise} The response data.
 */
export const getApi = async (link: string): Promise<any> => {
    const response = await fetch(link, { mode: 'cors' });
    if (response.ok) {
        const data_temp = await response.json();
        return data_temp;
    }
    return null;
}

/**
 * Sends a POST request to the specified link.
 * @param {string} link - The link to send the request to.
 * @return {Promise} The response data.
 */
export const postApi=async (link:string) : Promise<any> =>{
    const response = await fetch(link ,{mode: 'cors' , method: 'POST'})
    if (response.ok){
        return response.json()
    }
    return null;
}
/**
 * Sends a GET request to the specified link with Authorization.
 * @param {string} link - The link to send the request to.
 * @param {string} Auth - The Authorization token.
 * @return {Promise} The response data.
 */
export const  Locked_getApi=async (link:string , Auth:string) : Promise<any> =>{
    const response = await fetch(link ,{mode: 'cors',headers: new Headers({'Authorization':Auth}) , method: 'GET'})
    if (response.ok){
        var data_temp=response.json();
        return data_temp;
    }
    return null;
}
/**
 * Sends a POST request to the specified link with Authorization.
 * @param {string} link - The link to send the request to.
 * @param {string} Auth - The Authorization token.
 * @return {Promise} The response data.
 */
export const locked_postApi=async (link:string , Auth:string) : Promise<any>=>{
    const response = await fetch(link ,{mode: 'cors',headers: new Headers({'Authorization':Auth}) , method: 'POST'})
    if (response.ok){
        return response.json()
    }
    return null;
}

/**
 * Sends a POST request to the specified link with Authorization and body.
 * @param {string} link - The link to send the request to.
 * @param {string} Auth - The Authorization token.
 * @param {unknown} body - The body of the request.
 * @return {Promise} The response data.
 */
export const locked_postApi_body=async (link:string , Auth:string, body: unknown): Promise<any>=>{
    const response = await fetch(link ,{mode: 'cors',headers: new Headers({'Authorization':Auth}) , method: 'POST' , body: JSON.stringify(body)})
    if (response.ok){
        return response.json()
    }
    return null;
}

/**
 * Sends a GET request to the specified link with Authorization filled from getIdToken.
 * @param {string} link - The link to send the request to.
 * @return {Promise} The response data.
 */
export const Locked_getApi_authfill = async (link: string, refresh: boolean = false): Promise<any> => {
    let Auth: string = await getIdToken();
    let data_temp: any;

    // Check if the data in local storage is expired
    const isExpired = (timestamp: number): boolean => {
        const TWENTY_MINUTES = 15 * 60 * 1000; // 20 minutes in milliseconds
        return Date.now() - timestamp > TWENTY_MINUTES;
    }

    const storedData = localStorage.getItem(link);
    let isDataExpired = true;
    if (storedData) {
        try {
            const parsedData = JSON.parse(storedData);
            if (parsedData.timestamp && parsedData.data) {
                isDataExpired = isExpired(parsedData.timestamp);
                data_temp = parsedData.data;
            } else {
                // If the stored data doesn't have the new structure, assume it's old and set isDataExpired to true.
                isDataExpired = true;
            }
        } catch (error) {
            // If there's an error parsing the stored data, assume it's old and set isDataExpired to true.
            isDataExpired = true;
        }
    }
    // If refresh is true, local cache is empty, or data is expired, fetch data
    if (refresh || !storedData || isDataExpired) {
        const response = await fetch(link, {mode: 'cors', headers: new Headers({'Authorization': Auth}), method: 'GET'});

        if (response.ok) {
            data_temp = await response.json();
            // Store data and current timestamp in local cache
            localStorage.setItem(link, JSON.stringify({ data: data_temp, timestamp: Date.now() }));
        } else {
            return null;
        }
    }

    return data_temp;
}

  
/**
 * Sends a POST request to the specified link with Authorization filled from getIdToken.
 * @param {string} link - The link to send the request to.
 * @return {Promise} The response data.
 */
export const locked_postApi_authfill=async (link:string ): Promise<any>=>{
    let Auth:string=await getIdToken();
    const response = await fetch(link ,{mode: 'cors',headers: new Headers({'Authorization':Auth}) , method: 'POST'})
    if (response.ok){
        return response.json()
    }
    return null;
}

/**
 * Sends a POST request to the specified link with Authorization filled from getIdToken and body.
 * @param {string} link - The link to send the request to.
 * @param {unknown} body - The body of the request.
 * @return {Promise} The response data.
 */
export const locked_postApi_body_authfill=async (link:string , body: unknown) : Promise<any>=>{
    let Auth:string=await getIdToken();
    const response = await fetch(link ,{mode: 'cors',headers: new Headers({'Authorization':Auth}) , method: 'POST' , body: JSON.stringify(body)})
    if (response.ok){
        return response.json()
    }
    return null;
}

