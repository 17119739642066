import React, { useEffect } from 'react';
import { Box, Input, Typography } from '@mui/material'; // Assuming these are the components you're using
import CameraAltIcon from '@mui/icons-material/CameraAlt'; // Assuming this is the icon you're using

interface UploadImageProps {
  previewSrc: string | null;
  setPreviewSrc: (value: string) => void;
  selectedFile: File | null;
  setSelectedFile: (file: File) => void;
}

const UploadImage: React.FC<UploadImageProps> = ({
  previewSrc,
  setPreviewSrc,
  selectedFile,
  setSelectedFile,
}) => {
  const handlePaste = (e: ClipboardEvent ) => {
    if (e.clipboardData && e.clipboardData.files.length > 0) {
      const file = e.clipboardData.files[0];
      setSelectedFile(file);
      setPreviewSrc(URL.createObjectURL(file));
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      setPreviewSrc(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    window.addEventListener('paste', handlePaste as (e: Event) => void);
    return () => {
    window.removeEventListener('paste', handlePaste as (e: Event) => void);
    };
  }, []);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box
        maxWidth={'100%'}
          sx={{
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            backgroundColor:'#FFFDFD' ,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            cursor: 'pointer',
            height: '20rem',
            width: '30rem',
            maxWidth:'90%',
            px:'2rem',
          }}
          onClick={() => document.getElementById('fileUploadClass')?.click()}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {previewSrc ? (
              <img src={previewSrc} alt="Preview" style={{ width: 'auto', height: 'auto',maxHeight:'100%' , maxWidth:'100%' ,  borderRadius: '10%' }} />
            ) : (
              <>
              <div style={{ backgroundColor:'#D9D9D9' , borderRadius: '20px' , marginBottom:'2rem' , padding:'10px 20px'}}>
                <h2>
                  Upload Image
                </h2>
              </div>
              </>
            )}
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.webp"
              id="fileUploadClass"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files)
                setPreviewSrc(URL.createObjectURL(e.target.files[0]));
                handleFileInput(e);
              }}
            />
            {selectedFile ? null : (
              <Typography sx={{ fontSize: { xs: '16px', md: '20px' } }}>or drop a file, <br /> paste image
              </Typography>
            )}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default UploadImage;
