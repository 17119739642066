// Importing Amplify and Auth from AWS Amplify
import  { Amplify, Auth }  from 'aws-amplify';

// Configuring Amplify with the authentication details
import mixpanel from 'mixpanel-browser';
mixpanel.init('2ad5a13c5a6dae3491ebaceecb66a11f', { debug: true, track_pageview: true, persistence: 'localStorage' });

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLEINTID,
    oauth: {
      domain: process.env.REACT_APP_OAUTHDOMAN,
      scope: [
        "email",
        "openid",
        "profile"
      ],
      redirectSignIn: document.location.origin,
      redirectSignOut: document.location.origin,
      responseType: "code"
    },
  } , 
});

// Variable to store the ID token
let idToken: string = '';

// Function to check if the user is authenticated
export const checkAuth = async (): Promise<boolean> => {
  try {
    const data: any = await Auth.currentAuthenticatedUser();
    idToken = data.signInUserSession.idToken.jwtToken;
    sessionStorage.setItem('Login', 'true');
    return true;
  } catch {
    localStorage.clear();
    window.location.href = '/auth';
    return false;  
  }
}

// Temporary function to check if the user is authenticated
export const checkAuth_temp = async (): Promise<void> => {
  try {
    const data: any = await Auth.currentAuthenticatedUser();
    idToken = data.signInUserSession.idToken.jwtToken;
    sessionStorage.setItem('Login', 'true');
  } catch {
    window.location.href = '/auth';  
  }
}

// Function to get the ID token
export const getIdToken = (): Promise<string> => {
  const check = async (): Promise<string> => {
    await checkAuth_temp();
    return idToken;
  }

  return check().then(result => {
    if (idToken === '') {
      return check();
    }
    return idToken;
  });
}

// Function to log out the user
export const logout = (): void => {
  const check = async () => {
    await Auth.signOut();
    await checkAuth();
  }
  check();
}

// Function to check if the user is signed in
export const isUserSignedIn = async (): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch {
    return false;
  }
}

// Function to get the username of the authenticated user
export const getUsername = async (): Promise<string> => {
  const data: any = await Auth.currentAuthenticatedUser();
  return data.username;
}
