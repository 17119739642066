// src/component/pages/auth.tsx

import React, { useEffect, useState } from 'react';
import { checkAuth, logout } from '../auth/helper';
import { Heading, PrimaryButton, SmallText, TextBox } from '../ui/helper';
import UploadImage from '../ui/homepage_elements/uploadImage';
import Loading from '../ui/loading';
import { Compress } from '../computation/helper';
import { Locked_getApi_authfill, generateUrl, locked_postApi, locked_postApi_authfill, locked_postApi_body_authfill } from '../api/helper';
import { api_urls } from '../api/list';
import PastElement from '../ui/homepage_elements/pastElement';
import LoadingDots from '../ui/loadingAnimation';
import MessageBox from '../ui/MessageBox';
function Confirmpage() {
    const [previewSrc , setPreviewSrc]=useState<string | null>(null)
    const [selectedFile, setSelectedFile] =  useState<File | null>(null);
    const [localLoading, setLocalLoading] = useState(false);
    const [message,setMessage]=useState(['','']);
    const [messageopen , setMessageopen]=useState(false);
    const [pastImages,setPastImages]=useState([]);
    const [pending,setPending]=useState(false);
    const [special , setSpecial]=useState(false);
    useEffect(()=>{
        checkAuth();
    } , [])
 
  return (
    <div style={{width:'90%' , marginLeft:'5%' , minHeight:'80vh'}}>
      <Heading text='Payment successful ! Your credits are shown on the top'/>
      <img src='assets/ezgif.com-video-to-gif (4).gif' />
    </div>
  );
}

export default Confirmpage;
