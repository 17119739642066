import React from 'react';
import { PrimaryButton, handleDownload } from '../helper';
import { s3bucketurl } from '../../api/list';


interface PastElementProps {
    data:any;
  }

  
const PastElement: React.FC<PastElementProps> = ({ data  }) => {
  return (
    <div style={{ display:'flex' , alignItems:'center' , justifyContent:'center' , gap:'10vw' ,padding:'10px', marginTop:'2rem' }}>
        
        <div
    style={{
        width: '50vw',
        margin:'10px',
        height: '50vw',
        maxWidth:'20rem',
        maxHeight:'20rem',
        backgroundImage: `url(${data["base_link"].trim()})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '10px',
        position: 'relative', // Add this line
    }} />
    <div style={{ display:'flex' , alignItems:'center' , justifyContent:'center' , gap:'5rem' ,padding:'10px', marginTop:'2rem' , flexWrap:'wrap' }}>
        <PrimaryButton
                  text={"Download tiff"}
                  onClickfunction={() => {
                    handleDownload(s3bucketurl + data.tif, "Layered.tif");
                  }}
                />
        {data.layer && <PrimaryButton
                  text={"Download Zip"}
                  onClickfunction={() => {
                    handleDownload(s3bucketurl + data.layer, "Layered.zip");
                  }}
                />}
        </div>
    </div>
       
  );
};

export default PastElement;

