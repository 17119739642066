import { Dialog, DialogContent, Paper } from "@mui/material";
import { useState } from "react";
import { PrimaryButton, SmallText, Smallheading, Transition } from "./helper";
import { primarycolor } from "../constants/constants";

/**
 * A function component that returns an empty div.
 */
const MessageBox: React.FC <{ heading: string; message: string; setOpen:(value: boolean) => void; open: boolean;special:boolean }>= ({heading , message , open ,setOpen , special } ) => {
  return (
    <Dialog onClose={()=>{setOpen(false)}} open={open} maxWidth="xs"   TransitionComponent={Transition} fullWidth={true} >
            <div style={{ backgroundColor: primarycolor , display:'flex'  , alignItems:'center'  , justifyContent:'flex-end', flexWrap:'wrap' , margin:'20px 10px' }}>
                <div style={{width:'100%' , textAlign:'center'}}>
                    <Smallheading text={heading}/>
                </div>
                <div style={{width:'100%' , textAlign:'center' , marginBottom:'20px'}}>
                <SmallText text={message} />
                </div>
              {special &&<PrimaryButton text={'Buy Credits'} onClickfunction={()=>{window.location.href='/Pricing'}}/> }
              <div style={{width:'100%' , textAlign:'center' , marginBottom:'20px'}}></div>
            <PrimaryButton text={'close'} onClickfunction={()=>{setOpen(false)}}/>
            </div>
        
    </Dialog>
  );
};

export default MessageBox;

