import React, { FC, useState, useEffect } from 'react';
import PriceElement from '../ui/pricing_element/priceelement';
import { generateUrl, locked_postApi_authfill } from '../api/helper';
import { api_urls } from '../api/list';
import Loading from '../ui/loading';
import mixpanel from 'mixpanel-browser';
const Pricing: FC = () => {
    const [localLoading , setLocalLoading]=useState(false)
    async function payment(buycredits:number) {
        setLocalLoading(true)
        try{
            let data=await locked_postApi_authfill( generateUrl(api_urls['create_getPaymentlink'][2] ,{'credits':buycredits.toString() }))
            console.log(data);
            mixpanel.track('payment Window Opening', {
                'Status':'opening '
            })
            window.open(data[0])}
        catch{
            mixpanel.track('payment Window Opening', {
                'Status':'error'
            })
        }    
            setLocalLoading(false)
      }

    useEffect(() => {

        
    }, []);

    return (
        <div style={{minHeight:'80vh'}}>
            <h1>Pricing / Image</h1>
            <h3>1 image = 1 generated file </h3>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px', width:'80%', marginLeft:'10%'}}>
                <PriceElement heading={'Tier 1'} pricing={0.15} features={'Total cost :- $1.5'} totalImages={'10'} totalCost={''} onButtonClick={() => payment(1)} />
                <PriceElement heading={'Tier 2'} pricing={0.1} features={'Total cost :- $10'} onButtonClick={() => payment(10)}  totalImages={'100'} totalCost={' Cost : $10'}/>
                <PriceElement heading={'Tier 3'} pricing={0.075} features={'Total cost :- $18.75'} onButtonClick={() => payment(25)} totalImages={'250'} totalCost={' Cost : $18.75'}/>
                <PriceElement heading={'Tier 4'} pricing={0.05} features={'Total cost :- $25'} onButtonClick={() => payment(50)} totalImages={'500'} totalCost={' Cost : $25'}/>
            </div>
            <div style={{width:'80%', marginLeft:'10%' , marginTop:'5%'}}>
                <p style={{textAlign:'left'}}>*For image requests above 150 - please contact us for a custom package. </p>
                <p style={{textAlign:'left'}}>*API documentation coming soon</p>
                <p style={{textAlign:'left'}}>*Monthly subscriptions coming soon</p>    
            </div>                
            <Loading loading={localLoading} />
        </div>
    );
};

export default Pricing;
