import React, { FC } from 'react';
import { Heading, PrimaryButton, SmallText } from '../helper';

interface PriceElementProps {
    heading:string;
    pricing: number;
    features: string;
    onButtonClick: () => void;
    totalCost:string;
    totalImages:string;
}

const PriceElement: FC<PriceElementProps> = ({heading ,  pricing, features, onButtonClick ,totalCost , totalImages}) => {
    return (
        <div style={{filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",backgroundColor:'#FFFDFD' , borderRadius: '10px', padding: '20px'}}>
            <Heading text={`${heading}`}/>
            <h2>{`$ ${pricing} / Image`}</h2>
            <h2>{`${totalImages} Images`}</h2>
            <div style={{margin:'1rem'}}>
            <SmallText text={`${features}`}/>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <PrimaryButton text='Buy Now' onClickfunction={onButtonClick} />
            </div>
        </div>
    );
};

export default PriceElement;

