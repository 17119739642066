import React, { FC, useEffect, useState } from 'react';
import { exampleText, faqText, logoimg, secondrycolor, primarycolor } from '../constants/constants';
import { PrimaryButton, SmallText, UrlText  } from './helper';
import { logout } from '../auth/helper';
import { api_urls } from '../api/list';
import { Locked_getApi_authfill } from '../api/helper';

interface NavbarProps {}

const Footer: FC<NavbarProps> = () => {
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        console.log(window.location.pathname);
        setFadeIn(true);
        
    }, []);

    return (
        <div style={{borderTop: '0.34px #EDEDED solid', width:'100' , display:'flex' , justifyContent:'center'}}>
        <div 
            style={{
                width:'90%',
                display:'flex',
                alignItems:'center',
                background: primarycolor,
                animation: fadeIn ? 'fadeIn 0.2s ease-in-out' : '',
                padding:'10px' ,
                justifyContent:'space-between'
            }}
        >
         
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            
                
            </div>
            
            
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
             <a href="https://www.imagepsdlayers.com/terms.html" className="nav-link" style={{ color: secondrycolor, textDecoration: 'none' }} > <UrlText text='terms of service'/></a>    
            <div  style={{ animation: fadeIn ? 'fadeIn 0.2s ease-in-out' : ''}}>
                <SmallText text={'| Rights Reserved by Zyng ai'}/>
           </div>
            </div>
            
        </div>
        </div>
    );
};

export default Footer;
