// src/component/pages/auth.tsx

import React, { useEffect, useState } from 'react';
import { checkAuth, logout } from '../auth/helper';
import { Heading, PrimaryButton, SmallText, TextBox } from '../ui/helper';
import UploadImage from '../ui/homepage_elements/uploadImage';
import Loading from '../ui/loading';
import { Compress } from '../computation/helper';
import { Locked_getApi_authfill, generateUrl, locked_postApi, locked_postApi_authfill, locked_postApi_body_authfill } from '../api/helper';
import { api_urls } from '../api/list';
import PastElement from '../ui/homepage_elements/pastElement';
import LoadingDots from '../ui/loadingAnimation';
import MessageBox from '../ui/MessageBox';
import mixpanel from 'mixpanel-browser';
function Home() {
    const [previewSrc , setPreviewSrc]=useState<string | null>(null)
    const [selectedFile, setSelectedFile] =  useState<File | null>(null);
    const [localLoading, setLocalLoading] = useState(false);
    const [message,setMessage]=useState(['','']);
    const [messageopen , setMessageopen]=useState(false);
    const [pastImages,setPastImages]=useState([]);
    const [pending,setPending]=useState(false);
    const [special , setSpecial]=useState(false);
    async function pendingSites() {
      let data=await Locked_getApi_authfill(api_urls['getPendingLayer'][2] , true)
      setPending(data)
      if (data){
        setTimeout(() => {
          pendingSites();
          getPastData();
        },30000)
      }
    }
    async function getPastData(){
      setLocalLoading(true);
      let data=await Locked_getApi_authfill(api_urls['getPastLayer'][2] , true)

      setPastImages(data)
      setLocalLoading(false);
    }
    useEffect(()=>{
        checkAuth();
        getPastData();
        
        pendingSites();
    } , [])
    async function CreateLayer() {
      setSpecial(false)
        if ( !selectedFile) {
            console.log("exiting");
            return;
          }
          setLocalLoading(true);

          try {
            let temp = await Compress(selectedFile);
            let base64_img=temp? temp.split(",")[1]:'';
          if (base64_img.charAt(0) === '"' || base64_img.charAt(0) === "'") {
              base64_img = base64_img.substring(1, base64_img.length);
            }
          if (
              base64_img.charAt(base64_img.length - 1) === '"' ||
              base64_img.charAt(base64_img.length - 1) === "'"
            ) {
              base64_img = base64_img.substring(0, base64_img.length - 1);
            }
    
            let outputtemp=await locked_postApi_body_authfill(api_urls['createLayer'][2]  , {image:base64_img })
            console.log(outputtemp);
            
            if (outputtemp==='low on credits'){
              setMessage(['Error','No credits Left'])
              setMessageopen(true)
              setSpecial(true)
              mixpanel.track('Layer Generation', {
                'Status':'Out of Credits'
              })
            }
            else{
            setMessage(['Success','Layers are being Generated'])
            setMessageopen(true)
            pendingSites()
            mixpanel.track('platform button', {
              'Status':'creating'
            })
          }
        }
        catch  {
          setMessage(['Error','Some Error Please Try Again'])
          setMessageopen(true)
          mixpanel.track('platform button', {
            'Status':'error'
          })
        }
        setLocalLoading(false)
      }
  return (
    <div style={{width:'90%' , marginLeft:'5%'}}>
      <h1 style={{fontWeight:'normal'  , fontSize:'3rem'}}>
      Upload an image to <br /> create a layered file
      </h1>
     {/* <div style={{display:'flex' , width:'100%' , justifyContent:'space-between' , marginTop:'3rem' , marginBottom:'3rem'}}>
     <div style={{width:'12rem' }}>
            <PrimaryButton text='Learn More' onClickfunction={()=>{payment()}}/>
        </div>
        <div style={{width:'12rem'}}>
            <PrimaryButton text=' + Get More Credits' onClickfunction={()=>{payment()}}/>
        </div>
      </div> */}
    
    <UploadImage previewSrc={previewSrc} setPreviewSrc={setPreviewSrc}  selectedFile={selectedFile} setSelectedFile={setSelectedFile}/>
    <div style={{display:'flex', justifyContent:'center' , marginTop:'5vh' , marginBottom:'5vh'}}>
            <PrimaryButton text={'Create Layers'} onClickfunction={CreateLayer} />
        
    </div>
    <Heading text='Your generated files'/>
    <Loading loading={localLoading} />
    {/* <div style={{display:'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px' , width:'80%' , margin:'20px', marginLeft:'10%'}}> */}
    <div style={{display:'flex' ,justifyContent:'center' , marginTop:'5vh' , marginBottom:'5vh'}}>
      <div style={{width:'98%'}}>
      {pending && 
      <span>Generating Layered Files<LoadingDots /></span>
      }
        {pastImages.length>0 &&  pastImages.map((pastImage:any) => (
            <PastElement data={pastImage} key={pastImage.id}/>
        ))}
        </div>
    {/* </div> */}
    {/* <PastElement data={pastImages[0]}/> */}
    </div>
    {messageopen && (
          <MessageBox
            heading={message[0]}
            message={message[1]}
            setOpen={setMessageopen}
            open={messageopen}
            special={special}
          />
        )}
    </div>
  );
}

export default Home;
