import React from 'react';
import  { Amplify, Auth }  from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { secondrycolor } from '../../constants/constants';
import mixpanel from 'mixpanel-browser';
import { PrimaryButton } from '../helper';
Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_USERPOOLID,
      userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLEINTID,
      oauth: {
        domain: process.env.REACT_APP_OAUTHDOMAN,
        scope: [
          "email",
          "openid",
          "profile"
        ],
        redirectSignIn: document.location.origin,
        redirectSignOut: document.location.origin,
        responseType: "code"
      },
    } , 
  });
function GoogleSignInButton() {
    
    const buttonStyles = {
        backgroundColor: secondrycolor,
        color: 'white',
        border: 'none',
        borderRadius: '2px',
        padding: '10px 50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontWeight: '500',
        fontSize: '16px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    };

    const iconStyles = {
        width: '30px',
        height: '30px',
        marginRight: '10px'
    };

    const handleMouseOver = () => {
        // buttonStyles.backgroundColor = '#357abf';
    };

    const handleMouseOut = () => {
        // buttonStyles.backgroundColor = '#4285f4';
    };

    return (
        <button 
            style={buttonStyles} 
            onClick={() =>{  mixpanel.track('User Registration', {
                'button Type': 'signup'
              });
              Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google }  ) ; 
            } } 
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <img 
                style={iconStyles} 
                src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" 
                alt="Google Logo" 
            />
            Continue with Google
        </button>

    );
}

export default GoogleSignInButton;
