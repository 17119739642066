import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { loading, primarycolor } from '../constants/constants';
export default function Loading(props) {
  return (
      <Backdrop open={props.loading} 
        sx={{ zIndex:10  , backgroundColor: "rgba(255, 255, 255, 0.5)"  }} >
        
        <img
          src={loading}
          style={{
            backgroundRepeat: "no-repeat",
            borderRadius: "10px",
            width:'20rem',
            height:'auto ',
            opacity: 0.5
          }}
        />

      </Backdrop>

  );
}



