import React, { useState, useEffect } from 'react';

const LoadingDots: React.FC = () => {
  const [dots, setDots] = useState<string>('.');

  useEffect(() => {
    const interval = setInterval(() => {
      if (dots.length < 3) {
        setDots(dots + '.');
      } else {
        setDots('.');
      }
    }, 500); // This will update every half a second

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(interval);
    };
  }, [dots]);

  return <span>{dots}</span>;
};

export default LoadingDots;
