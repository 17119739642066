import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Auth from './component/pages/auth';
import Home from './component/pages/home';
import Header from './component/ui/header';
import Footer from './component/ui/footer';
import Pricing from './component/pages/pricing';
import Confirmpage from './component/pages/confirmPayment';
function App() {

  return (

    <div className="App">
      <div style={{ position: 'sticky', top: 0 ,  left: 0 , overflow:'hidden' }}>
      <Header />
      <Router>
        <Routes>
        <Route path="/"  element={<Home />} />
        <Route path='/pricing' element={<Pricing />}/>
        <Route path="/auth"  element={<Auth />} />
        <Route path="/payment"  element={<Confirmpage />} />
      </Routes>
        </Router>
      <Footer />
      </div>
    </div>
  );
}

export default App;
