import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Button, Grow, IconButton, MenuItem, Popover, Select, TextField, Typography } from '@mui/material';
import { primarycolor, secondrycolor } from '../constants/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TransitionProps } from '@mui/material/transitions';
import mixpanel from 'mixpanel-browser';
export const PrimaryButton: React.FC<{ text: string  , onClickfunction:any}> = ({ text , onClickfunction }) => {
 
    return (
      <>
        <Button
          style={{
            width: 'fit-content',
            height: 'fit-content',
            background: `${secondrycolor}`,
            padding:'10px 20px',
            borderRadius: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center' , 
            textTransform: 'none' ,
            gap:'0.75rem'
          }}
          onClick={() => {
            onClickfunction();
            mixpanel.track('platform button', {
              'button Type': text
            });
          }}
        >
           {/* mixpanel.track('platform button', {
    'button Type':text
  }) */}
          <img src='https://testbucketmarket.s3.ap-south-1.amazonaws.com/Group+1+(12).png'  style={{maxWidth:'2rem'}}/>
          <div style={{ color: `${primarycolor}`, fontSize: '1em' , fontFamily: 'DM Sans', fontWeight: '600', wordWrap: 'break-word' }}>{text}</div>
        </Button>
  
      </>
    );
  };
  
export const Heading:React.FC<{ text: string }> = ({ text  }) =>{
    return(
        <p
        style={{
          color: secondrycolor,
          fontFamily: 'DM Sans',
          fontSize:'3rem',
          fontWeight:'normal' , 
        }}>
        {text}
        </p>

    )
}

export const SmallText: React.FC<{ text: string }> = ({ text  }) => {
  return (
  
      <div style={{width: '100%', color: `${secondrycolor}` ,  fontSize: '1em', fontFamily: 'DM Sans' ,  fontWeight: 500 , }}>{text}</div>
    
  );
};
export const handleDownload = (Downloadlink :string, name:string) => {
  const link = document.createElement("a");
  link.href = Downloadlink;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export const TextBox: React.FC<{ value:  any, onChange: (value: any) => void  , itype:string}> = ({ value, onChange , itype}) => {
  return (
    <TextField
              autoComplete="off"
              value={value}
              sx={{width:'100%' , height:'100%'}}
              type={itype}
              onChange={(e)=>{onChange(e.target.value)}}
            />
  );
};
export const UrlText: React.FC<{ text: string }> = ({ text  }) => {
  return (
  
      <Typography style={{width: '100%', color: `${secondrycolor}` ,  fontSize: '1rem', fontFamily: 'DM Sans' ,  fontWeight: 500 , }}  sx={{
        '&:hover': {
          textDecoration: 'underline'
        },}}>{text}</Typography>
    
  );
};


export const Smallheading: React.FC<{ text: string, popovertext?: string , link?:string }> = ({ text, popovertext , link }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
    {popovertext &&<div style={{display:'flex' , alignItems:'center' , margin:'2rem'}}> 
      <p
        style={{
          color: secondrycolor,
          fontFamily: 'DM Sans',
          fontSize:'1.8rem',
          fontWeight:'500',
          margin:'0'
        }}>
        {text}
        </p>

        {popovertext && <>
          <IconButton
        // onMouseEnter={handlePopoverOpen}
        //   onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}>
          <InfoOutlinedIcon fontSize="small"  sx={{ color:secondrycolor }} />
        </IconButton>
          </>
          }
          
      
      </div>}
      {!popovertext && <p
        style={{
          color: secondrycolor,
          fontFamily: 'DM Sans',
          fontSize:'1.8rem',
          fontWeight:'500'
        }}>
        {text}
        </p>}
      {popovertext &&
      <Popover
      id={''}
      open={open}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      
      >
        <div style={{margin:'10px' , height:'100%'}}>
          <div style={{margin:'10px' , width:'30rem'}}>
          <SmallText text={popovertext}/>
        </div>
        {link &&  
        <div style={{marginRight:'10px' , width:'30rem' , height:'20rem'}}>
        <iframe
        width="100%"
        height="100%"
        src={link}
        style={{margin:'10px'}}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      </div>
        }
        </div>
      </Popover>}
    </>
  );
};


export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  
  return <Grow ref={ref} {...props} />;
});